<template>
  <div class="wordExerciseDetails">
    <WordList :list="list" :total="total" />
  </div>
</template>

<script>
import WordList from '@/views/basicSkills/wordExercise/components/WordList.vue'
import { neologismApi, recordPracticeApi } from '@/api/basicSkills/wordExercise'
import { mapState } from 'vuex'

export default {
  name: 'WordExerciseDetails',
  components: {
    WordList
  },
  data() {
    return {
      list: [],
      total: 0
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (val.path === '/wordExerciseDetails' && val.query.course) {
          this.getWordPractice(val.query.course)
        }
      }
    },
    wordKey(val) {
      this.getNeologism(val)
    }
  },
  computed: {
    ...mapState({
      wordKey: state => state.wordExercise.wordKey
    })
  },
  created() {
    const { course, name: type } = this.$route.query
    if (type === '生词本') {
      this.getNeologism()
    } else {
      this.getWordPractice(course)
    }
  },
  methods: {
    getWordPractice(classNum) {
      recordPracticeApi({
        getModel: 0,
        num: classNum
      }).then(res => {
        this.list = res.data
        this.total = res.data.length
      })
    },
    getNeologism(wordKey = '') {
      neologismApi({
        pageSize: 9999,
        pageNum: 1,
        wordKey: wordKey || ''
      }).then(res => {
        console.log(res)
        this.list = res.rows
        this.total = res.total
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wordExerciseDetails {
  min-height: 100%;
}
</style>
